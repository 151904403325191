
























































































































































































import { Component, Vue } from 'vue-property-decorator'
import AppNavDrawer from '@/components/App/AppNavDrawer.vue'
import ProductPictureUpload from '@/components/ProductPictureUpload.vue'
import axiosInstance from '@/plugins/axios'
import Swal from 'sweetalert2'

@Component({
  components: {
    AppNavDrawer,
    ProductPictureUpload
  }
})
export default class ProductAdd extends Vue {
  productTypes = []

  product = {
    name: null,
    brand: null,
    typeId: null,
    description: null,
    pictures: [],
    models: [
      {
        sku: null,
        price: null,
        originalPrice: null,
        model: null,
        color: null,
        storage: null,
        size: null,
        pictures: []
      }
    ]
  }

  created () {
    this.fetchTypes()
  }

  async fetchTypes (): Promise<void> {
    try {
      const { data } = await axiosInstance.get('/manager/types')
      console.log('PRODUCT_TYPES', data)
      this.productTypes = data
    } catch (error) {
      console.error(error)
    }
  }

  addModel (): void {
    this.product.models.push({
      sku: null,
      price: null,
      originalPrice: null,
      model: null,
      color: null,
      storage: null,
      size: null,
      pictures: []
    })
  }

  removeModel (index: number): void {
    this.product.models.splice(index, 1)
  }

  async saveProduct (): Promise<void> {
    try {
      this.$store.dispatch('app/setIsLoading', true)
      const res = await axiosInstance.post('/manager/saveproduct', {
        name: this.product.name,
        brand: this.product.brand,
        typeid: this.product.typeId,
        description: this.product.description,
        pictures: this.product.pictures,
        models: this.product.models.map(model => {
          return {
            sku: model.sku,
            // @ts-ignore
            price: parseInt(model.price),
            // @ts-ignore
            originalprice: parseInt(model.originalPrice),
            model: model.model,
            color: model.color,
            storage: model.storage,
            size: model.size,
            pictures: model.pictures
          }
        })
      })
      const data: any = res.data
      console.log('SAVE_PRODUCT_RESULT', data)
      this.$store.dispatch('app/setIsLoading', false)

      if (data.result !== 'OK') {
        Swal.fire({
          icon: 'error',
          title: data.message
        })
        return
      }

      await this.$router.push('/products')
    } catch (error) {
      console.error(error)
      this.$store.dispatch('app/setIsLoading', false)

      Swal.fire({
        icon: 'error',
        title: 'ກະລຸນາລອງໃໝ່ອີກຄັ້ງ'
      })
    }
  }
}
